import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import { graphql } from 'gatsby';
import { Row, Col, Typography } from 'antd';
import ExternalContent from "../components/ExternalContent";
const { Title } = Typography;

class Index extends Component {

    render() {

        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;
        const seoTitle = acfFields.meta_title ? acfFields.meta_title : page.title;

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={acfFields.meta_description}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <Row className="darkbg" style={{ padding: 24, textAlign: 'center'}}>
                    <Col span={24}>
                        <Title level={1}>{page.title}</Title>
                    </Col>
                </Row>

                <Row style={{ padding: 24}}>
                    <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                        <ExternalContent>
                            <div dangerouslySetInnerHTML={{__html: page.content }} />
                        </ExternalContent>
                    </Col>
                </Row>

            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query QueryStandardPage($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      template
      title
      content
      acf {
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
      }
    }
  }
`

export default Index
